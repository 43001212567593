.container {
  background-color: white; 
}

.navBar {
 
  height: 20%;
  width: 100%;
}

p {
  
  word-wrap: break-word; /* allow long words to break and wrap onto the next line */
  overflow-wrap: break-word; /* for non-standard browsers that support a different version of the word-wrap property */
}

.logo {
  width: 100%;
  height: 1%;

}

.title {
  color: white;
  font-size: 18px;
  font-weight: bold;
  
}
@media (max-width: 600px) {
  .logo {
    width: 100%;
    height: 15%;
  
    overflow: hidden;
    margin-top: 0px;
  }
}