@keyframes load {
    0% {
      opacity: 0.08;
      filter: blur(5px);
      letter-spacing: 3px;
    }
    100% {
      opacity: 1;
      filter: blur(0);
    }
  }
  
  .animate {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 26px;
    font-family: Helvetica, sans-serif, Arial;
    animation: load 1.2s infinite 0s ease-in-out;
    animation-direction: alternate;
    text-shadow: 0 0 1px white;
    color: black;
  }
  
  .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    background-color: #111;
    border-radius: 10px;
  }
  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure it overlays everything */
  }
  
  .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    background-color: transparent;
    border-radius: 10px;
    padding: 20px;
  }
  
  .animate {
    font-size: 26px;
    font-family: Helvetica, sans-serif, Arial;
    animation: load 1.2s infinite ease-in-out alternate;
    text-shadow: 0 0 1px black;
    color: #fff;
  }
  
  @keyframes load {
    0% {
      opacity: 0.08;
      filter: blur(5px);
      letter-spacing: 3px;
    }
    100% {
      opacity: 1;
      filter: blur(0);
    }
  }
  